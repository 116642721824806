<style lang="scss">
.finish-btn {
  width: 265px;
  height: 36px;
  background: #FF4158;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border: 0;
  margin-top: 10px;
}

.exp-wrapper {
  //position: absolute;
  //left: 0;
  //top: 46px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  background: #fff;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 0.10rem ;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;

  color: #3b5998;
}




.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }

}
.wrap{
  display:flex;
  flex-direction:column;
  height:100%;
}
.footer{
  height:40px;
  width: 100%;
  position: fixed;
  bottom:0;
  left:0;
}
.app-header {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  //border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  //box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.header-left, .header-right {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

}

.header-left {
  left: 0;
}

.header-right {
  right: 0;
}
.icon-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.go-home {
  display: block;
  width: 21px;
  height: 19px;
  background: url("../assets/images/go-home.png");
  background-size: contain;
}

</style>
<template>
  <div>
    <div class="app-header" >
      设备维护
      <div class="header-left" @click="$router.push({path:'/'})"><i class="iconfont icon-prev"></i></div>
      <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
    </div>
    <Panel class="tabs" :title="false">
    <div slot="content" class="status-content wrap" v-infinite-scroll="loadMainTain"
         infinite-scroll-disabled="loading"
         infinite-scroll-distance="10">
      <div class="line" v-for="(mt,idx) of mainTain">
        <router-link style="color: #1f1f1f" :to="{name:'addmainTain',query:{mainTainId:mt.id}}">
        <div class="flex-dir-row" style="color: #000000">
          {{ mt.maintenDate}}
          <div class="flex-item-auto exp-line-status">
            {{mt.maintenTypeName}}
          </div>
        </div>
        <div class="flex-dir-row  exp-line-title">{{mt.equipment}}</div>
        </router-link>
      </div>
      <div class="line text-center" v-if="mainTain.length===0">暂无记录</div>
    </div>
    </Panel>
    <div class="btn  green footer" @click="addmainTain">新增维护记录</div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import Panel from "@/components/Panel";
import dict from "@/plugins/dict";

export default {
  components: {
    Panel,
    AppHeader,
    InfiniteScroll
  },
  data() {
    return {
      page: 1,
      mainTain: [],
      loading: false,
      noMore: false,
      curExp: null,
      popupVisible: false,
      finalAccounts: false
    }
  },
  mounted() {
    this.initMainTain()
    dict.init()
  },
  methods: {
    initMainTain() {
      this.mainTain = []
      this.page = 1
      this.noMore = false
      this.loadMainTain()
    },
    loadMainTain() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '10')
      this.$http.post(`/api/equipmentMaintain/list`, data).then(res => {
        if (res.data && res.data.length > 0) {
          const mainTain = res.data
          that.mainTain = that.mainTain.concat(mainTain)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },

    displaySpent(spent) {
      let totalSeconds = Math.floor(spent / 1000)
      let hours = Math.floor(totalSeconds / 3600)
      let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      let seconds = totalSeconds - hours * 3600 - minutes * 60
      return this.fillZero(hours) + ':' + this.fillZero(minutes) + ':' + this.fillZero(seconds)
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY.MM.DD HH:mm')
    },
    fillZero(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    viewScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id,
          view: true
        }
      })
    },
    doScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id
        }
      })
    },

    goHome() {
      this.$router.push("/")
    },
    endExp() {
      const that = this
      that.popupVisible = true
    },
    cancelEnd(){
      this.popupVisible = false
    },
    addmainTain(){
      this.$router.push({
        path: '/addmainTain',

      })
    }
  }

}
</script>
